import NextLink from 'next/link'
import { Center, Flex, VStack, Text } from '@chakra-ui/react'
import { ITalktoSales } from '@/types/strapi/homePage.interface'
import { ContainerBase } from '@/components/containers/ContainerBase'

export const TalktoSales = ({
  talkToSales,
}: {
  talkToSales: ITalktoSales[]
}) => {
  const cms = talkToSales?.length > 0 ? talkToSales[0] : null

  if (!cms) return null

  return (
    <Center
      width="100%"
      height={{ base: '', md: '376px' }}
      background="linear-gradient(295.37deg, #FF531C 13.13%, #FF9A44 85.35%)"
    >
      <ContainerBase
        py="34px"
        px={{ base: 4, '2xl': '210px' }}
        maxWidth={{ '2xl': '1600px' }}
      >
        <Flex
          width="100%"
          background="white"
          borderRadius="8px"
          height={{ base: '', md: '312px' }}
          padding={{ base: '32px', md: '64px' }}
          alignItems="flex-start"
          gridGap="10px"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <VStack flex="1" alignItems="flex-start">
            <Text
              fontSize={{ base: '20px', md: '24px' }}
              color="brand.600"
              fontWeight="600"
            >
              {cms?.subtitle}
            </Text>
            <Text
              color="black"
              fontSize={{ base: '28px', md: '32px' }}
              fontWeight="600"
              pr={10}
            >
              {cms?.title}
            </Text>
          </VStack>
          <VStack flex="1" alignItems="flex-start" spacing="16px">
            <Text color='black' fontSize={{ base: '20px', md: '24px' }} lineHeight="32px">
              {cms?.description}
            </Text>
            {cms?.button && (
              <NextLink
                href={cms?.button?.url || '/contact-sales'}
                target={cms?.button?.newTab ? '_blank' : ''}
                passHref
              >
                <Center
                  as="a"
                  borderRadius="8px"
                  background="brand.600"
                  color="white"
                  width={{ base: '100%', md: '200px' }}
                  height="50px"
                  fontSize="16px"
                  _hover={{
                    background: 'brand.600',
                    opacity: 0.8,
                  }}
                >
                  {cms?.button?.text}
                </Center>
              </NextLink>
            )}
          </VStack>
        </Flex>
      </ContainerBase>
    </Center>
  )
}
